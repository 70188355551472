const Config = {
  apiKey: process.env.REACT_APP_SOLDI_API_KEY,
  secret: process.env.REACT_APP_SOLDI_API_SECRET,

  ssoUrl: process.env.REACT_APP_OAUTH_URL,
  baseUrl: process.env.REACT_APP_SOLDI_POS3_URL,
  apiUrl: `${process.env.REACT_APP_SOLDI_POS3_URL}/api`,

  irsBaseUrl: process.env.REACT_APP_SOLDI_IRS_URL,
  irsApiUrl: `${process.env.REACT_APP_SOLDI_IRS_URL}/api`,

  engageBaseUrl: process.env.REACT_APP_ENGAGE_API_BASE_URL,
  engageApiUrl: `${process.env.REACT_APP_ENGAGE_API_BASE_URL}/api`,

  deviceId: process.env.REACT_APP_API_DEVICE_ID,
  client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
  client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,

  mapBoxAPIKey: process.env.REACT_APP_MAP_BOX_API_KEY,

  phone: process.env.REACT_APP_PHONE,
  email: process.env.REACT_APP_EMAIL,
  address: process.env.REACT_APP_ADDRESS,
  instagram: process.env.REACT_APP_INSTAGRAM,
  facebook: process.env.REACT_APP_FACEBOOK,
  twitter: process.env.REACT_APP_TWITTER,
};

export default Config;
